import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../components/AuthContext';
import { useNavigate } from 'react-router-dom';

import { auth } from '../../config/firebase';
import { signOut, sendPasswordResetEmail } from 'firebase/auth';

import Footer from "../../components/Footer";
import Navbar from "../../components/navbar/Navbar";
import Image from '../../components/Image';

import '../../styles/account.css';

function Account() {
  const { user } = useAuth();
  const navigate = useNavigate();
  
  const [accountData, setAccountData] = useState({});
  const [loadingAccount, setLoadingAccount] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.uid) {
        try {
          setLoadingAccount(true);
          const response = await axios.get(`https://api.mylondoncorner.com/account/${user.uid}`, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY
            }
          });
          setAccountData(response.data);
          setLoadingAccount(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoadingAccount(false);
        }
      } else {
        setLoadingAccount(false);
        console.log("User is not authenticated or user data is not available.");
      }
    };

    fetchData();
  }, [user]);

  const logout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };

  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, user.email);
      alert("Email di reset della password inviata. Controlla la tua casella di posta in arrivo.");
    } catch (error) {
      console.error("Errore nell'invio dell'email di reset della password:", error.message);
      alert("Errore nell'invio dell'email di reset della password. Si prega di riprovare più tardi.");
    }
  };

  return (
    <>
      <Navbar />
      <br />
      <div className="home-container">
        <br />
        {loadingAccount ? (
          <div className="loading-animation"></div>
        ) : (
          <>
            {user ? (
              <div className='row'>
                <div className='account-left-column'>
                  <div className='account-username-box'>
                    <Image 
                      url={user.photoURL != null ? user.photoURL : "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"} 
                      style={{ width: '100px', height: '100px', marginBottom: '5px', display: 'inline-block', borderRadius: '50%' }}
                    />
                    <p>Bentornat*</p>
                    <h2>{user.isAnonymous ? 'Guest' : user.displayName ? user.displayName.split(' ')[0] : user.email}</h2>
                  </div>
                  <br />
                  <div className='account-username-box-2'>
                    {!user.isAnonymous && (
                      <>
                        <p onClick={handlePasswordReset} style={{ cursor: 'pointer' }}>Cambia password</p>
                        <br />
                      </>
                    )}
                    <p onClick={logout} style={{ cursor: 'pointer' }}>Logout</p>
                  </div>
                </div>
                <div className='account-right-column'>
                  <h2 style={{ textAlign: 'left' }}>Prenotazioni</h2>
                  <br />
                  <hr />
                  <br />
                  {accountData && accountData.tourBookings && accountData.tourBookings.length > 0 ? (
                    accountData.tourBookings.map((booking, index) => (
                      booking.status != 'cancelled' ? (
                        <div className='account-tour-booking' key={index}>
                          <h3>{formatDate(booking.date)}</h3>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <p style={{ fontSize: '15px', margin: '0' }}>{booking.tour.name}<br />{booking.quantity_adult} adulti, {booking.quantity_child} bambino</p>
                            <div style={{ marginLeft: 'auto' }}>
                                <button className='button' onClick={() => navigate('/tour/voucher/' + booking._id)}>Apri Voucher</button>
                            </div>
                          </div>
                        </div>
                      ) : (<p>Nessuna prenotazione.</p>)
                    ))
                  ) : (
                    <p>Nessuna prenotazione.</p>
                  )}
                </div>
              </div>
            ) : (
              navigate('/')
            )}
          </>
        )}
      </div>
      <Footer />
    </>
  );
}

// Define a function to format the date
function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
}

export default Account;

import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';

import { star } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

import { useNavigate } from 'react-router-dom';

import Navbar from '../components/navbar/Navbar';
import Footer from '../components/Footer';

import '../styles/post/post.css';
import '../styles/tables.css';
import '../styles/inputs.css';

function PrivateGuides() {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [arrivalDate, setArrivalDate] = useState('');
  const [departureDate, setDepartureDate] = useState('');
  const [option, setOption] = useState('Itinerario personalizzato di 1 giorno (£15)');
  const [accomodation, setAccomodation] = useState('');
  const [message, setMessage] = useState('');

  const handleItinerarySubmission = async () => {
    if(name !== '' && email !== '' && option !== '' && accomodation !== '' && arrivalDate !== '' && message !== '' && email.includes('@')) {
      axios.post('https://api.mylondoncorner.com/contact', {
        subject: option,
        name: name,
        email: email,
        message: `Start Date: ${arrivalDate}, Finish Date: ${departureDate}, Option: ${option}, Accomodation: ${accomodation}, Message: ` + message
      }, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      })
      .then((response) => {
        if(response.status === 200) {
          navigate('/contact/received')
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    } else {
      if(!email.includes('@')) {
        alert("E-mail non valida!");
      } else {
        alert("Tutti i campi devono essere compilati!");
      }
    }
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Guide Private</title>‍
        <meta name="description" content="Ottieni una guida privata in base alle tue necessità" />
        <link rel="canonical" href={process.env.REACT_APP_BASE_URL + "/private-guides"} />
        <meta name="twitter:card" content={'https://api.mylondoncorner.com/images/guide-private.jpg'} />        
        <meta name="twitter:site" content="@mylondoncorner" />        
        <meta name="twitter:creator" content="@mylondoncorner" />        
        <meta name="twitter:title" content="Il tuo viaggio a Londra! - mylondoncorner.com" />        
        <meta name="twitter:description" content="Benvenut* a Londra!" />        
        <meta name="twitter:image" content={'https://api.mylondoncorner.com/images/guide-private.jpg'} />        
        <meta property="og:title" content="Il tuo viaggio a Londra! - mylondoncorner.com" />        
        <meta property="og:description" content="Benvenut* a Londra!" />
        <meta property="og:image" content={'https://api.mylondoncorner.com/images/guide-private.jpg'} />
        <meta property="og:url" content={process.env.REACT_APP_BASE_URL + "/guide-private"} />
        <meta property="og:site_name" content="Il tuo viaggio a Londra! - mylondoncorner.com" />
        <meta property="og:locale" content="it_IT" />
        <meta property="og:type" content="website" />

        <meta name="keywords" content="guide,private,guida,personalizzato,londra" />
      </Helmet>

      <Navbar />

      <br /><br />

      <div className='post-container row'>
        <div className='post-left-column'>
          <h1 style={{ textAlign: 'center' }}>Richiedi una guida privata</h1>
          <br /><br />
          <img src='https://api.mylondoncorner.com/images/guide-private.jpg' className='post-header-image' alt="Image" />
          <br /><br />
          <p>
            Scoprite Londra in tutta comodità con le nostre guide turistiche private! 
            Godetevi <b>un'esperienza su misura</b> mentre esplorate la città a piedi, scegliendo tra le seguenti opzioni:
          </p>
          <br /><br />
          <table>
            <tr>
              <th>Durata</th>
              <th>Prezzo</th>
            </tr>
            <tr>
              <td>4 ore</td>
              <td>£160</td>
            </tr>
            <tr>
              <td>7 ore</td>
              <td>£210</td>
            </tr>
          </table>
          <br /><br />
          <h2>Info</h2>
          <br />
          <p>• Il massimo di partecipanti consentito per questi tour ai corrispettivi prezzi è di 10 persone.</p><br />
          <p>• Per gruppi più numerosi di 10 persone, vi preghiamo di contattarci via email o di indicarlo nel modulo di richiesta.</p><br />
          <p>• Potete scegliere l'orario di partenza che preferite, sia al mattino che al pomeriggio.</p><br />
          <p>• È possibile cancellare la prenotazione fino a 72 ore prima dell'inizio del tour per ottenere un rimborso completo.</p><br />
          <p>• La guida sarà in italiano, garantendo una migliore comprensione e fruizione dell'esperienza.</p>
          <br /><br />
          <h2>La tua esperienza</h2>
          <br />
          <p>• Ammira le icone principali della capitale.</p><br />
          <p>• Esplora a piedi o in macchina, su richiesta, le affascinanti strade di Londra.</p><br />
          <p>• Goditi le meravigliose attrazioni della città esternamente, o possono essere aggiunte con un supplemento.</p><br />
          <p>• Preparati a catturare momenti indimenticabili con numerose foto!</p><br />
          <p>• Non è incluso il biglietto della metropolitana o bus (siate pronti con un oyster card o carta contactless).</p>
          <br /><br />
          <h2>Informazioni utili</h2>
          <br />
          <p>• Indossa qualcosa di caldo nei periodi invernali.</p><br />
          <p>• Indossa scarpe comode.</p><br />
          <p>• Goditi un'esperienza super British!</p>
          <br /><br />
          <p>
            Affidatevi alla nostra <b>esperienza e alla conoscenza</b> del luogo per scoprire i segreti nascosti della città e lasciatevi sorprendere dalla bellezza di Londra!
          </p><br />
          <p>
            Prenotate subito il vostro tour personalizzato e privato con noi.
          </p><br />
          <p>
            Se siete un tour operator o agenzia di viaggio, e volete collaborare offrendo i nostri fantastici tour ai vostri clienti, o avete bisogno di tour per gruppi in viaggio a Londra, potete contattarci su info@mylondoncorner.com
          </p><br /><br />
          <h2>Richiedi la tua Guida Privata</h2>
          <br />
          <p>Inserisci il tuo nome</p>
          <input type='text' id="name" name="name" onChange={(e) => setName(e.target.value)} placeholder='Inserisci il tuo nome' />
          <br /><br />
          <p>Inserisci la tua e-mail</p>
          <input type='email' id="email" name="email" onChange={(e) => setEmail(e.target.value)} placeholder='Inserisci la tua e-mail' />
          <br /><br />
          <p>Inserisci data del tour</p>
          <input type='date' id="arrivalDate" name="arrivalDate" onChange={(e) => setArrivalDate(e.target.value)} />
          <br /><br />
          <p>Inserisci data di fine dei tour (Se piú di 1 giorno)</p>
          <input type='date' id="departureDate" name="departureDate" onChange={(e) => setDepartureDate(e.target.value)} />
          <br /><br />
          <p>Seleziona un'opzione</p>
          <select id="option" name="option" onChange={(e) => setOption(e.target.value)}>
            <option value='1 giorno, 4 ore (£160)'>1 giorno, 4 ore (£160)</option>
            <option value='1 giorno, 7 ore (£210)'>1 giorno, 7 ore (£210)</option>
            <option value='2 giorni, 4 ore (£290)'>2 giorni, 4 ore (£320)</option>
            <option value='2 giorni, 7 ore (£400)'>2 giorni, 7 ore (£410)</option>
            <option value='3 giorni, 4 ore (£400)'>3 giorni, 4 ore (£480)</option>
            <option value='3 giorni, 7 ore (£490)'>3 giorni, 7 ore (£630)</option>
            <option value='4 giorni, 4 ore (£500)'>4 giorni, 4 ore (£640)</option>
            <option value='4 giorni, 7 ore (£610)'>4 giorni, 7 ore (£840)</option>
            <option value='5 giorni, 4 ore (£560)'>5 giorni, 4 ore (£560)</option>
            <option value='5 giorni, 7 ore (£710)'>5 giorni, 7 ore (£1050)</option>
            <option value='Piú giorni e ore (£--)'>Piú giorni e ore (£--)</option>
          </select>
          <br /><br />
          <p>Inserisci il tuo alloggio o punto di incontro preferito</p>
          <input type='text' id="accomodation" name="accomodation" onChange={(e) => setAccomodation(e.target.value)} placeholder="Inserisci il tuo alloggio o punto di incontro preferito" />
          <br /><br />
          <p>Cosa vorresti vedere?</p>
          <textarea id="message" rows={6} name="message" onChange={(e) => setMessage(e.target.value)} placeholder='Cosa vorresti vedere? Cosa non puoi assolutamente perdere? Qualche attrazione?' />
          <br /><br />
          <button className='button' onClick={handleItinerarySubmission}>Richiedi</button>
          <br /><br />
        </div>
        <div className='post-hr-only-small'>
          <br />
          <hr />
          <br />
        </div>
        <div className='post-right-column'>
          <a href="https://www.gttickets.com/it/londra/london-warner-bros-studio-tour-london-dietro-le-quinte-di-harry-potter-viaggio-di-andata-ritorno?r=mylondoncorner.gttickets.com" className='a-not-decorated'>
            <img src="https://api.mylondoncorner.com/images/uploads/1678613445939--harry-potter-studios.jpg" style={{ borderRadius: '10px' }} alt='Warner Bros Studio Tour' />
            <br />
            <p><b>Warner Bros. Studio Tour con trasporto in Bus A/R</b></p>
            <br />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IonIcon icon={star} style={{ color: 'gold' }} />
              <span style={{marginLeft: '5px' }}>4.6</span>
              <p style={{ marginLeft: 'auto' }}>da <b>£105</b></p>
            </div>
            <br />
          </a>

          <br />

          <hr />

          <br />

          <a onClick={(e) => { e.preventDefault(); navigate(`/tour/tour-a-piedi-della-londra-iconica`); }} className='a-not-decorated'>
            <img src="https://api.mylondoncorner.com/images/uploads/1723321874297--a.jpg" style={{ borderRadius: '10px' }} alt='Tour a piedi della Londra Iconica' />
            <br />
            <p><b>Tour a piedi della Londra Iconica</b></p>
            <br />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IonIcon icon={star} style={{ color: 'gold' }} />
              <span style={{marginLeft: '5px' }}>5.0</span>
              <p style={{ marginLeft: 'auto' }}>da <b>£15</b></p>
            </div>
            <br />
          </a>

          <br />

          <hr />

          <br />

          <a onClick={(e) => { e.preventDefault(); navigate(`/tour/tour-a-piedi-di-harry-potter-a-londra`); }} className='a-not-decorated'>
            <img src="https://api.mylondoncorner.com/images/uploads/1724746135622--a.jpg" style={{ borderRadius: '10px' }} alt='Tour a Piedi di Harry Potter: Visita ai Luoghi delle Riprese' />
            <br />
            <p><b>Tour a piedi di Harry Potter a Londra</b></p>
            <br />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IonIcon icon={star} style={{ color: 'gold' }} />
              <span style={{marginLeft: '5px' }}>5.0</span>
              <p style={{ marginLeft: 'auto' }}>da <b>£20</b></p>
            </div>
            <br />
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivateGuides;
import React from 'react';
import '../../styles/widgets/Banner.css';

const Banner = ({ title, description, link, linkText }) => {
  return (
    <div className="banner">
      <div className="banner-overlay">
        <div className="banner-content">
          <h1>{title}</h1>
          <p>{description}</p>
          <br />
          <a href={link}>
            <button className="button">{linkText}</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Banner;
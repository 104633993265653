import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';

import { star } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

import { useNavigate } from 'react-router-dom';

import Navbar from '../components/navbar/Navbar';
import Footer from '../components/Footer';

import '../styles/post/post.css';
import '../styles/tables.css';
import '../styles/inputs.css';

function PersonalisedItineraries() {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [arrivalDate, setArrivalDate] = useState('');
  const [departureDate, setDepartureDate] = useState('');
  const [option, setOption] = useState('Itinerario personalizzato di 1 giorno (£15)');
  const [accomodation, setAccomodation] = useState('');
  const [message, setMessage] = useState('');

  const handleItinerarySubmission = async () => {
    if(name !== '' && email !== '' && option !== '' && accomodation !== '' && arrivalDate !== '' && departureDate !== '' && message !== '' && email.includes('@')) {
      axios.post('https://api.mylondoncorner.com/contact', {
        subject: option,
        name: name,
        email: email,
        message: `Arrival Date: ${arrivalDate}, Departure Date: ${departureDate}, Option: ${option}, Accomodation: ${accomodation}, Message: ` + message
      }, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      })
      .then((response) => {
        if(response.status === 200) {
          navigate('/contact/received')
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    } else {
      if(!email.includes('@')) {
        alert("E-mail non valida!");
      } else {
        alert("Tutti i campi devono essere compilati!");
      }
    }
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Itinerari Personalizzati</title>‍
        <meta name="description" content="Ottieni un itinerario personalizzato in base alle tue necessità" />
        <link rel="canonical" href={process.env.REACT_APP_BASE_URL + "/personalised-itineraries"} />
        <meta name="twitter:card" content={'https://api.mylondoncorner.com/images/personalised-itineraries.jpg'} />        
        <meta name="twitter:site" content="@mylondoncorner" />        
        <meta name="twitter:creator" content="@mylondoncorner" />        
        <meta name="twitter:title" content="Il tuo viaggio a Londra! - mylondoncorner.com" />        
        <meta name="twitter:description" content="Benvenut* a Londra!" />        
        <meta name="twitter:image" content={'https://api.mylondoncorner.com/images/personalised-itineraries.jpg'} />        
        <meta property="og:title" content="Il tuo viaggio a Londra! - mylondoncorner.com" />        
        <meta property="og:description" content="Benvenut* a Londra!" />
        <meta property="og:image" content={'https://api.mylondoncorner.com/images/personalised-itineraries.jpg'} />
        <meta property="og:url" content={process.env.REACT_APP_BASE_URL + "/personalised-itineraries"} />
        <meta property="og:site_name" content="Il tuo viaggio a Londra! - mylondoncorner.com" />
        <meta property="og:locale" content="it_IT" />
        <meta property="og:type" content="website" />

        <meta name="keywords" content="itinerari,personalizzati,itinerario,personalizzato,londra" />
      </Helmet>

      <Navbar />

      <br /><br />

      <div className='post-container row'>
        <div className='post-left-column'>
          <h1 style={{ textAlign: 'center' }}>Richiedi itinerario personalizzato</h1>
          <br /><br />
          <img src='https://api.mylondoncorner.com/images/personalised-itineraries.jpg' className='post-header-image' alt="Image" />
          <br /><br />
          <p>Desideri organizzare la tua prossima vacanza a Londra ma non sai da dove cominciare?</p>
          <br />
          <p>
            La pianificazione dell'itinerario perfetto richiede tempo e molta ricerca, ma non preoccuparti... Lascia che ci occupiamo di tutto per te!
          </p>
          <br />
          <p>
            Il nostro servizio è pensato appositamente per creare un itinerario su misura in base alle tue preferenze e esigenze, 
            garantendoti una vacanza indimenticabile senza stress!
          </p>
          <br /><br />
          <h2>Cosa includiamo?</h2>
          <br />
          <p>• Realizzazione di un dettagliato itinerario personalizzato consegnato in formato PDF via email.</p><br />
          <p>• Prenotazioni per attrazioni e tour.</p><br />
          <p>• Organizzazione di trasferimenti via taxi, treno o autobus.</p><br />
          <p>• Consigli su ristoranti, fast food e pub.</p><br />
          <p>• Fornitura di informazioni utili per il tuo viaggio.</p><br />
          <p>• Supporto in italiano prima e durante il tuo soggiorno a Londra via E-mail o Whatsapp.</p>
          <br /><br />
          <h2>Informazioni aggiuntive</h2>
          <br />
          <p>• I costi relativi ad attrazioni, tour o trasferimenti prenotati durante la creazione dell'itinerario non sono inclusi nel prezzo e ti saranno comunicati prima della conferma finale.</p><br />
          <p>• Eventuali biglietti o conferme di prenotazione saranno inviati tramite email, si consiglia la stampa di tutti i documenti.</p>
          <br /><br />
          <h3>Completa il modulo qui sotto per richiedere il tuo itinerario!</h3>
          <br /><br />
          <h2>Richiedi Itinerario Personalizzato</h2>
          <br />
          <p style={{fontSize: '9px !important'}}>Inserisci il tuo nome</p>
          <input type='text' id="name" name="name" onChange={(e) => setName(e.target.value)} placeholder='Inserisci il tuo nome' />
          <br /><br />
          <p>Inserisci la tua e-mail</p>
          <input type='email' id="email" name="email" onChange={(e) => setEmail(e.target.value)} placeholder='Inserisci la tua e-mail' />
          <br /><br />
          <p>Inserisci data di arrivo</p>
          <input type='date' id="arrivalDate" name="arrivalDate" onChange={(e) => setArrivalDate(e.target.value)} placeholder='Inserisci data del tuo arrivo A Londra' />
          <br /><br />
          <p>Inserisci data di partenza</p>
          <input type='date' id="departureDate" name="departureDate" onChange={(e) => setDepartureDate(e.target.value)} placeholder='Inserisci la tua partenza DA Londra' />
          <br /><br />
          <p>Seleziona un'opzione</p>
          <select id="option" name="option" onChange={(e) => setOption(e.target.value)}>
            <option value='Itinerario personalizzato di 1 giorno (£15)'>Itinerario personalizzato di 1 giorno (£15)</option>
            <option value='Itinerario personalizzato di 2 giorni (£25)'>Itinerario personalizzato di 2 giorni (£25)</option>
            <option value='Itinerario personalizzato di 3 giorni (£35)'>Itinerario personalizzato di 3 giorni (£35)</option>
            <option value='Itinerario personalizzato di 4 giorni (£45)'>Itinerario personalizzato di 4 giorni (£45)</option>
            <option value='Itinerario personalizzato di 5 giorni (£60)'>Itinerario personalizzato di 5 giorni (£60)</option>
            <option value='Itinerario personalizzato di 6 giorni (£75)'>Itinerario personalizzato di 6 giorni (£75)</option>
            <option value='Itinerario personalizzato di 7 giorni (£90)'>Itinerario personalizzato di 7 giorni (£90)</option>
            <option value='Itinerario personalizzato di 8 giorni (£110)'>Itinerario personalizzato di 8 giorni (£110)</option>
            <option value='Itinerario personalizzato di più giorni (£--)'>Itinerario personalizzato di più giorni (£--)</option>
          </select>
          <br /><br />
          <p>Inserisci il tuo alloggio</p>
          <input type='text' id="accomodation" name="accomodation" onChange={(e) => setAccomodation(e.target.value)} placeholder="Inserisci l'indirizzo del tuo alloggio" />
          <br /><br />
          <p>Cosa vorresti vedere?</p>
          <textarea id="message" rows={6} name="message" onChange={(e) => setMessage(e.target.value)} placeholder='Cosa vorresti vedere? Cosa non puoi assolutamente perdere? Qualche attrazione?' />
          <br /><br />
          <button className='button' onClick={handleItinerarySubmission}>Richiedi</button>
          <br /><br />
        </div>
        <div className='post-hr-only-small'>
          <br />
          <hr />
          <br />
        </div>
        <div className='post-right-column'>
          <a href="https://www.gttickets.com/it/londra/london-warner-bros-studio-tour-london-dietro-le-quinte-di-harry-potter-viaggio-di-andata-ritorno?r=mylondoncorner.gttickets.com" className='a-not-decorated'>
            <img src="https://api.mylondoncorner.com/images/uploads/1678613445939--harry-potter-studios.jpg" style={{ borderRadius: '10px' }} alt='Warner Bros Studio Tour' />
            <br />
            <p><b>Warner Bros. Studio Tour con trasporto in Bus A/R</b></p>
            <br />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IonIcon icon={star} style={{ color: 'gold' }} />
              <span style={{marginLeft: '5px' }}>4.6</span>
              <p style={{ marginLeft: 'auto' }}>da <b>£105</b></p>
            </div>
            <br />
          </a>

          <br />

          <hr />

          <br />

          <a onClick={(e) => { e.preventDefault(); navigate(`/tour/tour-a-piedi-della-londra-iconica`); }} className='a-not-decorated'>
            <img src="https://api.mylondoncorner.com/images/uploads/1723321874297--a.jpg" style={{ borderRadius: '10px' }} alt='Tour a piedi della Londra Iconica' />
            <br />
            <p><b>Tour a piedi della Londra Iconica</b></p>
            <br />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IonIcon icon={star} style={{ color: 'gold' }} />
              <span style={{marginLeft: '5px' }}>5.0</span>
              <p style={{ marginLeft: 'auto' }}>da <b>£15</b></p>
            </div>
            <br />
          </a>

          <br />

          <hr />

          <br />

          <a onClick={(e) => { e.preventDefault(); navigate(`/tour/tour-a-piedi-di-harry-potter-a-londra`); }} className='a-not-decorated'>
            <img src="https://api.mylondoncorner.com/images/uploads/1724746135622--a.jpg" style={{ borderRadius: '10px' }} alt='Tour a Piedi di Harry Potter: Visita ai Luoghi delle Riprese' />
            <br />
            <p><b>Tour a piedi di Harry Potter a Londra</b></p>
            <br />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IonIcon icon={star} style={{ color: 'gold' }} />
              <span style={{marginLeft: '5px' }}>5.0</span>
              <p style={{ marginLeft: 'auto' }}>da <b>£20</b></p>
            </div>
            <br />
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PersonalisedItineraries;
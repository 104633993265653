import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';

import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/Footer';

import SliderCategories from '../../components/widgets/SliderCategories';

import '../../styles/tickets.css';

function Tours() {
  const navigate = useNavigate();

  const [toursData, setToursData] = useState([]);
  const [loadingTours, setLoadingTours] = useState(true);

  const [categoriesData, setCategoriesData] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);

  useEffect(() => {
    setLoadingTours(true);
    axios.get(`https://api.mylondoncorner.com/tours/`, {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
      }
    })
    .then((response) => {
      setToursData(response.data);
      setLoadingTours(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingTours(false);
    });
  }, []);

  // UNIQUE CATEGORIES TO DISPLAY
  useEffect(() => {
    if (toursData.tours) {
      let uniqueCategories = toursData.tours.map((tour) => tour.category);
      uniqueCategories = uniqueCategories.flat(); // Flatten the array of arrays
  
      // Create a Set of unique _id values
      const uniqueIds = new Set(uniqueCategories.map(category => category._id));
  
      // Convert Set back to array
      const uniqueCategoriesWithoutDuplicates = Array.from(uniqueIds).map(id =>
        uniqueCategories.find(category => category._id === id)
      );

      setCategoriesData(uniqueCategoriesWithoutDuplicates);
  
      console.error(uniqueCategoriesWithoutDuplicates);
    }
  }, [toursData]);

  return (
    <>
      <Navbar />
      <br /><br />
      <div className='tickets-container'>
        <p style={{ fontWeight: 'bold' }}>Prenota qui le tue</p>
        <h1>Attività</h1>
        <br />
        <SliderCategories categories={categoriesData} type={'tours'} />
        <br />
        <h2>Tutti i tour</h2>
        <br />
        {loadingTours ? (
          <div className="loading-animation"></div>
        ) : (
          <>
            <div className='tickets-display-large'>
              {toursData.tours.map((tour, index) => {
                if (index % 4 === 0) {
                  return (
                    <div className='row' key={index}>
                      {toursData.tours
                        .filter(item => item.visible === true)
                        .slice(index, index + 4)
                        .map((tour, innerIndex) => (
                          <div className='ticket-column' onClick={() => window.location.href = tour.external_link} key={innerIndex}>
                            <img src={'https://api.mylondoncorner.com/' + tour.images_paths[0]} style={{ cursor: 'pointer', width: '100%', borderRadius: '10px', border: 'solid 1px #ccc' }} alt="Image" />
                            <p style={{ textAlign: 'left', marginTop: '3%', fontSize: '15px' }}>{tour.minimumAge === 0 ? 'Adatto a tutte le età' : 'Da ' + tour.minimumAge + ' anni'} • {tour.duration}</p>
                            <h4 style={{ textAlign: 'left', marginTop: '2%' }}>{tour.name}</h4>
                            <p style={{ textAlign: 'left', marginTop: '2%', fontSize: '15px' }}>{tour.car_base_price ? 'Da £' + tour.car_base_price + ' / macchina' : tour.price_child != 0 ? 'Da £' + tour.price_child + ' / persona' : tour.price != 0 ? 'Da £' + tour.price + ' / persona' : 'GRATIS' }</p>
                          </div>
                        ))}
                    </div>
                  );
                }
                return null;
              })}
            </div>
            <div className='tickets-display-small'>
              {toursData.tours.map((tour, index) => {
                if (index % 2 === 0) {
                  return (
                    <div className='row' key={index}>
                      {toursData.tours
                        .filter(item => item.visible === true)
                        .slice(index, index + 2)
                        .map((tour, innerIndex) => (
                          <div className='ticket-column' onClick={() => window.location.href = tour.external_link} key={innerIndex}>
                            <img src={'https://api.mylondoncorner.com/' + tour.images_paths[0]} style={{ cursor: 'pointer', width: '100%', borderRadius: '10px', border: 'solid 1px #ccc' }} alt="Image" />
                            <p style={{ textAlign: 'left', marginTop: '3%', fontSize: '15px' }}>{tour.minimumAge === 0 ? 'Adatto a tutte le età' : 'Da ' + tour.minimumAge + ' anni'} • {tour.duration}</p>
                            <h4 style={{ textAlign: 'left', marginTop: '2%' }}>{tour.name}</h4>
                            <p style={{ textAlign: 'left', marginTop: '2%', fontSize: '15px' }}>{ tour.price_child != 0 ? 'Da £' + tour.price_child + ' / persona' : tour.price != 0 ? 'Da £' + tour.price + ' / persona' : 'GRATIS' }</p>
                          </div>
                        ))}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
}

export default Tours;
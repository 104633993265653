import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import CookieConsent from "react-cookie-consent";

import HomeNavbar from '../components/navbar/HomeNavbar';
import Footer from '../components/Footer';

import { bookOutline, helpBuoyOutline, walletOutline, footstepsOutline, airplaneOutline, ticketOutline, heartOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

import Banner from '../components/widgets/Banner';
import ServiceCard from '../components/ServiceCard';
import SliderTrips from '../components/widgets/SliderTrips';
import NewsletterPopup from '../components/widgets/NewsletterPopup';
import SliderTours from '../components/widgets/SliderTours';

import '../styles/home.css';

function Home() {

  const navigate = useNavigate();

  const [tourData, setTourData] = useState([]);
  const [loadingTours, setLoadingTours] = useState(true);

  const [popularToursData, setPopularToursData] = useState([]);
  const [loadingPopularToursData, setLoadingPopularToursData] = useState(true);

  const [tripData, setTripData] = useState([]);
  const [loadingTrips, setLoadingTrips] = useState(true);

  const [postData, setPostData] = useState([]);
  const [loadingPosts, setLoadingPosts] = useState(true);

  useEffect(() => {
    // Make the API call when the component mounts
    axios.get('https://api.mylondoncorner.com/tours/category/name/I%20nostri%20Tour', {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
      }
    })
    .then((response) => {
      setTourData(response.data.tours); // Update the data state with the API response
      setLoadingTours(false); // Set loading to false once data is fetched
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingTours(false); // Set loading to false on error as well
    });

    // Make the API call when the component mounts
    axios.get('https://api.mylondoncorner.com/trips', {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
      }
    })
    .then((response) => {
      setTripData(response.data.trips); // Update the data state with the API response
      setLoadingTrips(false); // Set loading to false once data is fetched
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingTrips(false); // Set loading to false on error as well
    });

    // Make the API call when the component mounts
    axios.get('https://api.mylondoncorner.com/tours/popular', {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
      }
    })
    .then((response) => {
      setPopularToursData(response.data.tours); // Update the data state with the API response
      setLoadingPopularToursData(false); // Set loading to false once data is fetched
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingPopularToursData(false); // Set loading to false on error as well
    });

    // Make the API call when the component mounts
    axios.get('https://api.mylondoncorner.com/posts/limit/3', {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
      }
    })
    .then((response) => {
      setPostData(response.data.posts); // Update the data state with the API response
      setLoadingPosts(false); // Set loading to false once data is fetched
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingPosts(false); // Set loading to false on error as well
    });
    
  }, []);

  useEffect(() => {
    // Load Trustpilot script
    const script = document.createElement('script');
    script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Il tuo viaggio a Londra! - mylondoncorner.com</title>‍
        <meta name="description" content="Benvenut* a Londra!" />        
        <meta name="twitter:card" content="https://api.mylondoncorner.com/images/homepage/featured-banner-title.jpg" />        
        <meta name="twitter:site" content="@mylondoncorner" />        
        <meta name="twitter:creator" content="@mylondoncorner" />        
        <meta name="twitter:title" content="Il tuo viaggio a Londra! - mylondoncorner.com" />        
        <meta name="twitter:description" content="Benvenut* a Londra!" />        
        <meta name="twitter:image" content="https://api.mylondoncorner.com/images/homepage/featured-banner-title.jpg"/>        
        <meta property="og:title" content="Il tuo viaggio a Londra! - mylondoncorner.com" />        
        <meta property="og:description" content="Benvenut* a Londra!" />        
        <meta property="og:image" content="https://api.mylondoncorner.com/images/homepage/featured-banner-title.jpg" />
        <meta property="og:url" content={process.env.REACT_APP_BASE_URL} />
        <meta property="og:site_name" content="Il tuo viaggio a Londra! - mylondoncorner.com" />
        <meta property="og:locale" content="it_IT" />
        <meta property="og:type" content="website" />

        <meta name="keywords" content="Viaggi a Londra, Turismo a Londra, Attrazioni di Londra, Cose da fare a Londra, Guida di Londra, Vacanza a Londra, Visite turistiche a Londra, Hotel a Londra, Trasporti a Londra, Luoghi famosi di Londra, mylondoncorner" />
      
        {/* TrustBox script */}
        <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
        {/* End TrustBox script */}
      </Helmet>
    
      <HomeNavbar />

      <div className='home-top-bar'>
        <h2>
          <a href={`/`}>
            <span><IonIcon icon={heartOutline} />La tua guida a Londra</span>
          </a>
          <a href={`/tour-di-londra`}>
            <span><IonIcon icon={footstepsOutline} />Tour in italiano</span>
          </a>
          <a href={`/trips`}>
            <span><IonIcon icon={airplaneOutline} />Viaggi di gruppo</span>
          </a>
          <a href={`/tours`}>
            <span><IonIcon icon={ticketOutline} />Attrazioni</span>
          </a>
        </h2>
      </div>

      <br /><br />

      <div className='home-container'>

        <h1 className='home-shorter-title'>Scopri Londra con una guida in italiano!</h1>
        <br />
        <hr />
        <br />
        <p>Dal classico Tour di Londra al magico Tour di Harry Potter! Ne abbiamo per tutti, e tu, che tip* sei?</p>
        <br /><br />
        <div>
          {tourData
            .filter(item => item.visible === true) // Filter visible tours
            .slice(0, 3) // Take only the first 3
            .map((tour, index) => (
              <div className='home-tour-column' onClick={() => navigate(`/tour/${tour.slug}`)} key={index}>
                <div className='home-tour-column-image-container'>
                  <img src={`https://api.mylondoncorner.com/${tour.images_paths[0]}`} alt="Image" />
                </div>
                <p>
                  {tour.minimumAge === 0 ? 'Tour in italiano' : 'Da ' + tour.minimumAge + ' anni'} • {tour.duration}
                </p>
                <h4>{tour.name}</h4>
                <p className='home-tour-column-smaller-p'>{tour.short_description}</p>
                <p>
                  {tour.car_base_price ? 'Da £' + tour.car_base_price + ' / macchina' :
                    tour.price_child != 0 ? 'Da £' + tour.price_child + ' / persona' :
                    tour.price != 0 ? 'Da £' + tour.price + ' / persona' : 'GRATIS'}
                </p>
              </div>
            ))
          }
        </div>
        <p style={{ color: 'white' }}>I migliori tour di Londra</p>

        <button className='button' onClick={() => { navigate(`/tour-di-londra`) }}>Scopri piú tour</button>

        <br /><br /><br /><br />

        <h1>Partecipa a un Viaggio di Gruppo!</h1>
        <br />
        <hr />
        <br />
        <p>Arricchisci la tua esperienza condividendola con altri viaggiatori come te!</p>
        <br /><br />
        {loadingTrips ? (
          <div className="loading-animation"></div>
        ) : (
          <SliderTrips images={tripData} />
        )}
        <br />
        <button className='button' onClick={() => { navigate(`/trips`) }}>Scopri piú viaggi</button>

        <br /><br /><br /><br /><br />

      </div>

      <Banner 
        title={"Vivi la Magia del Warner Bros. Studio Tour Londra"} 
        description={"Acquista ora i tuoi biglietti e scopri il Mondo Magico!"} 
        link={"https://www.gttickets.com/it/londra/london-warner-bros-studio-tour-london-dietro-le-quinte-di-harry-potter-viaggio-di-andata-ritorno?r=mylondoncorner.gttickets.com"}
        linkText={"Acquista Biglietti"}
      />

      <div className='home-container'>

        <br /><br /><br /><br /><br />

        <h1>Alla ricerca di Attrazioni Popolari?</h1>
        <br />
        <hr />
        <br />
        <p>Scopri cosa Londra ha da offrire! Clicca sul bottone sotto per scoprire più attività...</p>
        <br /><br />
        {loadingPopularToursData ? (
          <div className="loading-animation"></div>
        ) : (
          <SliderTours images={popularToursData} />
        )}
        <br />
        <button className='button' onClick={() => { navigate(`/tours`) }}>Scopri piú attività</button>
        
        <br /><br /><br /><br /><br />

        <h1>Benvenut* su mylondoncorner.com</h1>
        <br />
        <hr />
        <br />
        <p>London is calling! <b>Scopri con noi ogni angolo di Londra</b>, divertiti e organizza la tua visita nella capitale piú amata d'Europa. Esplora il nostro sito e <b>lasciati guidare da noi</b> tra teatri, ristoranti, attrazioni e tanto altro ancora per un'<b>esperienza indimenticabile</b>!</p>
        <br />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '80%', margin: '0 auto' }} className='row'>
          <div style={{ margin: '2% 5%', float: 'left', width: '33.33%' }}>
            <IonIcon icon={bookOutline} style={{ fontSize: '70px' }} />
            <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Esperienza</p>
          </div>
          <div style={{ margin: '2% 5%', float: 'left', width: '33.33%' }}>
            <IonIcon icon={walletOutline} style={{ fontSize: '70px' }} />
            <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Pagamenti sicuri</p>
          </div>
          <div style={{ margin: '2% 5%', float: 'left', width: '33.33%' }}>
            <IonIcon icon={helpBuoyOutline} style={{ fontSize: '70px' }} />
            <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Supporto in italiano</p>
          </div>
        </div>

        <br /><br /><br /><br /><br />

        <h1>Servizi piú richiesti</h1>
        <br />
        <hr />
        <br />
        <div className='services'>
          <ServiceCard 
            img={'https://api.mylondoncorner.com/images/personalised-itineraries.jpg'}
            url={'/personalised-itineraries'}
            title={'Itinerari Personalizzati'}
            description={'Richiedi un itinerario su misura per te, ci pensiamo noi!'}
            bgcolor={'#288eed'}
          />
          <ServiceCard 
            img={'https://api.mylondoncorner.com/images/guide-private.jpg'}
            url={'/private-guides'}
            title={'Guide Private'}
            description={'Richiedi una guida privata che ti accompagnera durante il tuo viaggio'}
            bgcolor={'#288eed'}
          />
          <ServiceCard 
            img={'https://api.mylondoncorner.com/images/taxi.jpg'}
            url={'/taxi'}
            title={'Taxi tra Londra e Aeroporti'}
            description={'Arriva in aeroporto in tempo, comodo e rilassato!'}
            bgcolor={'#288eed'}
          />
        </div>

        <br /><br /><br /><br /><br />

        <h1>Il nostro Blog</h1>
        <br />
        <hr />
        <br />
        <p>Siamo dei veri esperti di Londra e il Regno Unito, scopri cosa ti aspetta!</p>
        <br /><br />
        {loadingPosts ? (
          <div className="loading-animation"></div>
        ) : (
          <div className='home-blog-post-row'>
            {postData.map((post) => (
              <div className='home-blog-post-column' onClick={() => navigate(`/post/${post.slug}`)} key={post.slug}>
                <div className="square-image-container">
                  <img className="square-image" src={'https://api.mylondoncorner.com/' + post.image_path} alt="Blog Post" />
                  <div className="overlay">
                    <h4>{post.title}</h4>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <br />
        <button className='button' onClick={() => { navigate(`/blog`) }}>Visita il Blog</button>

      </div>

      <br /><br /><br /><br />

      <Footer />

      <NewsletterPopup />

      <CookieConsent
        location="bottom"
        buttonText="Ho capito"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={1}
        debug={false}
      >
        Questo sito web utilizza i cookie per le sessioni di accesso al fine di migliorare l'esperienza dell'utente e garantire la sicurezza.{" "}
      </CookieConsent>
    </>
  );
}

export default Home;
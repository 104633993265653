import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { db, auth, GoogleProvider } from '../../config/firebase';
import { signInWithEmailAndPassword, signInWithPopup, signInAnonymously } from 'firebase/auth';
import { collection, setDoc, getDoc, doc } from 'firebase/firestore';

import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/Footer';

import InputText from '../../components/inputs/InputText';

import '../../styles/signup.css';

function Login() {

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const usersCollectionRef = collection(db, "users");

  const searchParams = new URLSearchParams(window.location.search); 
  const redirect = searchParams.get('redirect');

  const login = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      if(redirect === null) {
        navigate('/');
      } else {
        navigate(redirect);
      }
    } catch (err) {
      alert(err)
    }
  };

  const loginWithGoogle = async () => {
    try {
      const cred = await signInWithPopup(auth, GoogleProvider);
      // Check if the user already exists in the database
      const userDoc = doc(usersCollectionRef, cred.user.uid);
      const docSnap = await getDoc(userDoc);
      if (!docSnap.exists()) {
        // If not, add the user to firestore db
        await setDoc(userDoc, {
          email: cred.user.email,
          registrationDate: new Date()
        });

        // POST user to MongoDB
        axios.post('https://api.mylondoncorner.com/user/create', {
          firebaseUserId: cred.user.uid,
          email: cred.user.email,
          name: cred.user.displayName.split(' ')[0],
          surname: cred.user.displayName.split(' ').slice(1).join(' ')
        }, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
          }
        });
      }
      if(redirect === null) {
        navigate('/');
      } else {
        navigate(redirect);
      }
    } catch (err) {
      alert(err);
    }
  };

  // Function to handle anonymous login
  const loginAnonymously = async () => {
    try {
      await signInAnonymously(auth);
  
      // Since signInAnonymously doesn't return user credentials directly,
      // we need to use the onAuthStateChanged listener to get the user ID
      auth.onAuthStateChanged((user) => {
        if (user) {
          // POST user to MongoDB
          axios.post('https://api.mylondoncorner.com/user/create', {
            firebaseUserId: user.uid,
            // Since anonymous users don't have email and displayName, you might want to set them as null or omit them
            email: null,
            name: null,
            surname: null
          }, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
            }
          });
          if(redirect === null) {
            navigate('/');
          } else {
            navigate(redirect);
          }
        } else {
          // Handle anonymous login errors or the case where the user object is null
        }
      });
    } catch (err) {
      // Handle anonymous login errors
    }
  };

  return (
    <>
      <Navbar />
      <div className="row">
        <br></br>
        <div class="signup-box">
          <p style={{ fontSize: '22px' }}><b>Benvenut* su mylondoncorner</b></p>
          <br />
          <InputText type='email' label='E-mail' id='email' name='email' required='true' width='100%' onChange={(e) => setEmail(e.target.value)} />
          <br />
          <InputText type='password' label='Password' id='password' name='password' required='true' width='100%' onChange={(e) => setPassword(e.target.value)} />     
          <br /><br />
          <button className='button' style={{ backgroundColor: '#288eed', border: '0px', color: 'white' }} onClick={login}>Login</button>
          <br />
          <p style={{ cursor: 'pointer' }} onClick={() => navigate('/reset-password')}><b>Hai dimenticato la password?</b></p>
          <br />
          <hr class="hr-text" data-content="oppure"></hr>
          <br />
          <button className='button' style={{ textAlign: 'center' }} onClick={loginWithGoogle}>
            <img className='signup-social-logo' src='https://static.vecteezy.com/system/resources/previews/012/871/371/non_2x/google-search-icon-google-product-illustration-free-png.png' alt='Google Icon' style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '10px' }} />
            <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>Continua con Google</span>
          </button>
          <br />
          <button className='button' style={{ textAlign: 'center' }} onClick={loginAnonymously}>
            <img className='signup-social-logo' src='https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png' alt='Google Icon' style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '10px', borderRadius: '50%' }} />
            <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>Continua come ospite</span>
          </button>
          <br />
          <button className='button' style={{ textAlign: 'center' }} onClick={() => navigate('/signup')}>
            <img className='signup-social-logo' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQE1c-jCeKAzujuMaiCIepORoVoRJ7dWO3ubPE6gQdstQ&s' alt='Google Icon' style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '10px' }} />
            <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>Continua con email</span>
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Login;
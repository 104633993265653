import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';

import { star } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

import { useNavigate } from 'react-router-dom';

import Navbar from '../components/navbar/Navbar';
import Footer from '../components/Footer';

import '../styles/post/post.css';
import '../styles/tables.css';
import '../styles/inputs.css';

function Taxi() {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [airport, setAirport] = useState('');
  const [itinerary, setItinerary] = useState('');
  const [arrivalDate, setArrivalDate] = useState('');
  const [arrivalFlightNumber, setArrivalFlightNumber] = useState('');
  const [arrivalFlightTime, setArrivalFlightTime] = useState('');
  const [departureDate, setDepartureDate] = useState('');
  const [departureFlightNumber, setDepartureFlightNumber] = useState('');
  const [departureFlightTime, setDepartureFlightTime] = useState('');
  const [accomodation, setAccomodation] = useState('');
  const [passengers, setPassengers] = useState('');
  const [message, setMessage] = useState('');

  const handleItinerarySubmission = async () => {
    if(name !== '' && email !== '' && phone !== '' && airport !== '' && itinerary !== '' && accomodation !== '' && passengers !== '' && email.includes('@')) {
      axios.post('https://api.mylondoncorner.com/contact', {
        subject: 'Richiesta Trasferimento Taxi',
        name: name,
        email: email,
        message: `
          Phone Number: ${phone}, 
          Airport: ${airport},
          Itinerary: ${itinerary}, 
          Arrival Date: ${arrivalDate}, 
          Arrival Flight Number: ${arrivalFlightNumber}, 
          Arrival Flight Time: ${arrivalFlightTime}, 
          Arrival Date: ${departureDate}, 
          Arrival Flight Number: ${departureFlightNumber}, 
          Arrival Flight Time: ${departureFlightTime}, 
          Accomodation: ${accomodation}, 
          Passengers: ${passengers}, 
          Message: ` + message
      }, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      })
      .then((response) => {
        if(response.status === 200) {
          navigate('/contact/received')
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    } else {
      if(!email.includes('@')) {
        alert("E-mail non valida!");
      } else {
        alert("Tutti i campi devono essere compilati!");
      }
    }
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Taxi</title>‍
        <meta name="description" content="Ottieni un taxi privato base alle tue necessità" />
        <link rel="canonical" href={process.env.REACT_APP_BASE_URL + "/taxi"} />
        <meta name="twitter:card" content={'https://api.mylondoncorner.com/images/taxi.jpg'} />        
        <meta name="twitter:site" content="@mylondoncorner" />        
        <meta name="twitter:creator" content="@mylondoncorner" />        
        <meta name="twitter:title" content="Il tuo viaggio a Londra! - mylondoncorner.com" />        
        <meta name="twitter:description" content="Benvenut* a Londra!" />        
        <meta name="twitter:image" content={'https://api.mylondoncorner.com/images/taxi.jpg'} />        
        <meta property="og:title" content="Il tuo viaggio a Londra! - mylondoncorner.com" />        
        <meta property="og:description" content="Benvenut* a Londra!" />
        <meta property="og:image" content={'https://api.mylondoncorner.com/images/taxi.jpg'} />
        <meta property="og:url" content={process.env.REACT_APP_BASE_URL + "/taxi"} />
        <meta property="og:site_name" content="Il tuo viaggio a Londra! - mylondoncorner.com" />
        <meta property="og:locale" content="it_IT" />
        <meta property="og:type" content="website" />

        <meta name="keywords" content="itinerari,personalizzati,itinerario,personalizzato,londra" />
      </Helmet>

      <Navbar />

      <br /><br />

      <div className='post-container row'>
        <div className='post-left-column'>
          <h1 style={{ textAlign: 'center' }}>Taxi tra Londra e Aeroporti</h1>
          <br /><br />
          <img src='https://api.mylondoncorner.com/images/taxi.jpg' className='post-header-image' alt="Image" />
          <br /><br />
          <p>Prenota con noi il tuo trasferimento privato da Londra a tutti gli aereoporti e viceversa. Il servizio include una tratta, servizio privato e autista professionista.</p>
          <br /><br />
          <h2>Informazioni importanti</h2>
          <br />
          <p>• In ogni macchina possono stare un massimo di 7 persone.</p><br />
          <p>• I prezzi della tabella sono a tratta (Da aeroporto a Londra o da Londra a aeroporto).</p><br />
          <p>• I prezzi della tabella includono solamente 1 bagaglio a mano a persona.</p><br />
          <p>• Per bagagli da stiva, sedie a rotelle, sacche da golf, seggiolini etc., si applicherà un supplemento, comunicaci se ne hai bisogno all'interno del form di richiesta .</p><br />
          <br />
          <table>
            <tr>
              <th>Aeroporti</th>
              <th>1-3 Pax</th>
              <th>4 Pax</th>
              <th>5-6-7 Pax</th>
              <th>8 Pax</th>
            </tr>
            <tr>
              <td>Heathrow</td>
              <td>£100</td>
              <td>£115</td>
              <td>£140</td>
              <td>£145</td>
            </tr>
            <tr>
              <td>Stansted</td>
              <td>£130</td>
              <td>£145</td>
              <td>£170</td>
              <td>£180</td>
            </tr>
            <tr>
              <td>Gatwick</td>
              <td>£130</td>
              <td>£145</td>
              <td>£170</td>
              <td>£180</td>
            </tr>
            <tr>
              <td>Luton</td>
              <td>£130</td>
              <td>£145</td>
              <td>£170</td>
              <td>£180</td>
            </tr>
            <tr>
              <td>London City</td>
              <td>£100</td>
              <td>£115</td>
              <td>£140</td>
              <td>£145</td>
            </tr>
          </table>
          <br /><br />
          <h2>Richiedi Taxi</h2>
          <br />
          <p>Inserisci il tuo nome</p>
          <input type='text' id="name" name="name" onChange={(e) => setName(e.target.value)} placeholder='Inserisci il tuo nome' />
          <br /><br />
          <p>Inserisci la tua e-mail</p>
          <input type='email' id="email" name="email" onChange={(e) => setEmail(e.target.value)} placeholder='Inserisci la tua e-mail' />
          <br /><br />
          <p>Inserisci il tuo numero di telefono</p>
          <input type='text' id="phone" name="phone" onChange={(e) => setPhone(e.target.value)} placeholder='Inserisci il tuo numero di telefono' />
          <br /><br />
          <p>Seleziona aeroporto</p>
          <select id="airport" name="airport" onChange={(e) => setAirport(e.target.value)}>
            <option selected disabled>Selezione un'opzione</option>
            <option value='Heathrow'>Heathrow</option>
            <option value='Gatwick'>Gatwick</option>
            <option value='Stansted'>Stansted</option>
            <option value='Luton'>Luton</option>
            <option value='London City'>London City</option>
          </select>
          <br /><br />
          <p>Seleziona tratta</p>
          <select id="itinerary" name="itinerary" onChange={(e) => setItinerary(e.target.value)}>
            <option selected disabled>Selezione un'opzione</option>
            <option value='Da Londra a Aeroporto'>Da Londra a Aeroporto</option>
            <option value='Da Aeroporto a Londra'>Da Aeroporto a Londra</option>
            <option value='Andata e Ritorno'>Andata e Ritorno</option>
          </select>
          <br /><br />
          <p>Data di arrivo (Se applicabile)</p>
          <input type='date' id="arrivalDate" name="arrivalDate" onChange={(e) => setArrivalDate(e.target.value)} />
          <br /><br />
          <p>Numero del volo dell'arrivo (Se applicabile)</p>
          <input type='text' id="arrivalFlightNumber" name="arrivalFlightNumber" onChange={(e) => setArrivalFlightNumber(e.target.value)} placeholder="Inserisci numero del volo dell'arrivo (Se applicabile)" />
          <br /><br />
          <p>Orario del volo dell'arrivo (Se applicabile)</p>
          <input type='time' id="arrivalFlightTime" name="arrivalFlightTime" onChange={(e) => setArrivalFlightTime(e.target.value)} placeholder="Inserisci orario del volo dell'arrivo (Se applicabile)" />
          <br /><br />
          <p>Data di partenza (Se applicabile)</p>
          <input type='date' id="departureDate" name="departureDate" onChange={(e) => setDepartureDate(e.target.value)} />
          <br /><br />
          <p>Numero del volo della partenza (Se applicabile)</p>
          <input type='text' id="departureFlightNumber" name="departureFlightNumber" onChange={(e) => setDepartureFlightNumber(e.target.value)} placeholder="Inserisci numero del volo dell'arrivo (Se applicabile)" />
          <br /><br />
          <p>Orario del volo della partenza (Se applicabile)</p>
          <input type='time' id="departureFlightTime" name="departureFlightTime" onChange={(e) => setDepartureFlightTime(e.target.value)} placeholder="Inserisci orario del volo dell'arrivo (Se applicabile)" />
          <br /><br />
          <p>Inserisci il tuo alloggio o altro luogo per il drop-off</p>
          <input type='text' id="accomodation" name="accomodation" onChange={(e) => setAccomodation(e.target.value)} placeholder="Inserisci il tuo alloggio o altro luogo per il drop-off" />
          <br /><br />
          <p>Inserisci il numero di passeggeri adulti e bambini</p>
          <input type='number' id="passengers" name="passengers" onChange={(e) => setPassengers(e.target.value)} placeholder="Inserisci il numero di passeggeri adulti e bambini" />
          <br /><br />
          <p>Richieste particolari</p>
          <textarea id="message" rows={6} name="message" onChange={(e) => setMessage(e.target.value)} placeholder='Qualche richiesta particolare?' />
          <br /><br />
          <button className='button' onClick={handleItinerarySubmission}>Richiedi</button>
          <br /><br />
        </div>
        <div className='post-hr-only-small'>
          <br />
          <hr />
          <br />
        </div>
        <div className='post-right-column'>
          <a href="https://www.gttickets.com/it/londra/london-warner-bros-studio-tour-london-dietro-le-quinte-di-harry-potter-viaggio-di-andata-ritorno?r=mylondoncorner.gttickets.com" className='a-not-decorated'>
            <img src="https://api.mylondoncorner.com/images/uploads/1678613445939--harry-potter-studios.jpg" style={{ borderRadius: '10px' }} alt='Warner Bros Studio Tour' />
            <br />
            <p><b>Warner Bros. Studio Tour con trasporto in Bus A/R</b></p>
            <br />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IonIcon icon={star} style={{ color: 'gold' }} />
              <span style={{marginLeft: '5px' }}>4.6</span>
              <p style={{ marginLeft: 'auto' }}>da <b>£105</b></p>
            </div>
            <br />
          </a>

          <br />

          <hr />

          <br />

          <a onClick={(e) => { e.preventDefault(); navigate(`/tour/tour-a-piedi-della-londra-iconica`); }} className='a-not-decorated'>
            <img src="https://api.mylondoncorner.com/images/uploads/1723321874297--a.jpg" style={{ borderRadius: '10px' }} alt='Tour a piedi della Londra Iconica' />
            <br />
            <p><b>Tour a piedi della Londra Iconica</b></p>
            <br />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IonIcon icon={star} style={{ color: 'gold' }} />
              <span style={{marginLeft: '5px' }}>5.0</span>
              <p style={{ marginLeft: 'auto' }}>da <b>£15</b></p>
            </div>
            <br />
          </a>

          <br />

          <hr />

          <br />

          <a onClick={(e) => { e.preventDefault(); navigate(`/tour/tour-a-piedi-di-harry-potter-a-londra`); }} className='a-not-decorated'>
            <img src="https://api.mylondoncorner.com/images/uploads/1724746135622--a.jpg" style={{ borderRadius: '10px' }} alt='Tour a Piedi di Harry Potter: Visita ai Luoghi delle Riprese' />
            <br />
            <p><b>Tour a piedi di Harry Potter a Londra</b></p>
            <br />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IonIcon icon={star} style={{ color: 'gold' }} />
              <span style={{marginLeft: '5px' }}>5.0</span>
              <p style={{ marginLeft: 'auto' }}>da <b>£20</b></p>
            </div>
            <br />
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Taxi;
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';

import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/Footer';

import '../../styles/trip/trips.css';

function Trips() {
  const navigate = useNavigate();

  const [tripsData, setTripsData] = useState([]);
  const [loadingTrips, setLoadingTrips] = useState(true);

  useEffect(() => {
    setLoadingTrips(true);
    axios.get(`https://api.mylondoncorner.com/trips/`, {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
      }
    })
    .then((response) => {
      setTripsData(response.data);
      setLoadingTrips(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingTrips(false);
    });
  }, []);

  return (
    <>
      <Navbar />
      <br /><br />
      <div className='trips-container'>
        <p style={{ fontWeight: 'bold' }}>Prenota qui i tuoi</p>
        <h1>Viaggi di gruppo</h1>
        <br />
        {loadingTrips ? (
          <div className="loading-animation"></div>
        ) : (
          <>
            <div>
              <h2>Viaggi in partenza</h2>
              <br />
              {tripsData.trips
              .filter(item => item.visible === true)
              .map((trip, index) => {
                if (index % 3 === 0) {
                  return (
                    <div key={`row-${index}`} className='row'>
                      {tripsData.trips.filter(item => item.visible === true && new Date() < new Date(item.departure_date)).slice(index, index + 3).map((trip, subIndex) => (
                        <div key={`trip-${index + subIndex}`} className='trips-column' onClick={() => navigate('/trip/' + trip.slug)}>
                          <img src={`https://api.mylondoncorner.com/${trip.images_paths[0]}`} alt='Trip Image' />
                          <p style={{ textAlign: 'left', marginTop: '3%', fontSize: '15px' }}>{formatDateItalian(trip.departure_date) + '-' + formatDateItalianWithMonth(trip.return_date)} • {calculateNights(trip.departure_date, trip.return_date)} notti</p>
                            <h4 style={{ textAlign: 'left', marginTop: '2%' }}>{trip.name}</h4>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2%', fontSize: '15px' }}>
                              <p style={{ textAlign: 'left', fontWeight: 'bold', color: 'black' }}>{'Da ' + trip.price + '€ / persona'}</p>
                              <p style={{ textAlign: 'right', color: trip.status === 'open' ? 'green' : 'red', fontWeight: 'bold' }}>{trip.status === 'open' ? 'Aperto' : trip.status === 'cancelled' ? 'Cancellato' : 'SOLD OUT'}</p>
                            </div>
                        </div>
                      ))}
                    </div>
                  );
                }
                return null;
              })}
              <br /><br />
              <hr />
              <br /><br />
              <h2>Viaggi precedenti</h2>
              <br />
              {tripsData.trips
              .filter(item => item.visible === true)
              .map((trip, index) => {
                if (index % 3 === 0) {
                  return (
                    <div key={`row-${index}`} className='row'>
                      {tripsData.trips.filter(item => item.visible === true && new Date() > new Date(item.departure_date)).slice(index, index + 3).map((trip, subIndex) => (
                        <div key={`trip-${index + subIndex}`} className='trips-column' onClick={() => navigate('/trip/' + trip.slug)}>
                          <img src={`https://api.mylondoncorner.com/${trip.images_paths[0]}`} alt='Trip Image' />
                          <p style={{ textAlign: 'left', marginTop: '3%', fontSize: '15px' }}>{formatDateItalian(trip.departure_date) + '-' + formatDateItalianWithMonth(trip.return_date)} • {calculateNights(trip.departure_date, trip.return_date)} notti</p>
                            <h4 style={{ textAlign: 'left', marginTop: '2%' }}>{trip.name}</h4>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2%', fontSize: '15px' }}>
                              <p style={{ textAlign: 'left', fontWeight: 'bold', color: 'black' }}>{'Da ' + trip.price + '€ / persona'}</p>
                              <p style={{ textAlign: 'right', color: trip.status === 'open' ? 'green' : 'red', fontWeight: 'bold' }}>{trip.status === 'open' ? 'Aperto' : trip.status === 'cancelled' ? 'Cancellato' : 'SOLD OUT'}</p>
                            </div>
                        </div>
                      ))}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
}

const formatDateItalianWithMonth = (dateString) => {
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  return new Date(dateString).toLocaleDateString('it-IT', options);
};

const formatDateItalian = (dateString) => {
  const options = { day: 'numeric' };
  return new Date(dateString).toLocaleDateString('it-IT', options);
};

function calculateNights(departure_date, return_date) {
  const nights = [];
  let currentDate = new Date(departure_date);

  while (currentDate <= new Date(return_date)) {
    nights.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return nights.length - 1;
}

export default Trips;
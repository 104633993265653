import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Footer from "../../components/Footer";
import Navbar from "../../components/navbar/Navbar";
import '../../styles/blog.css';  // Import the new CSS file for blog-specific styles

export default function Blog() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    axios.get('https://api.mylondoncorner.com/posts', {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY
      }
    })
    .then((response) => {
      setPosts(response.data);
      setLoading(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Navbar />
      <div className="blog-container">
        <br />
        <h2>@mylondoncorner Blog</h2>
        <br />
        <hr />
        <br />
        <br />
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="posts">
            {posts.posts.map(post => (
              <div key={post.id} className="post zoom" onClick={() => navigate(`/post/${post.slug}`)}>
                <div className="post-image-wrapper">
                  <img src={'https://api.mylondoncorner.com/' + post.image_path} alt={post.title} className="post-image"/>
                  <div className="post-content">
                    <h3 className="post-title">{post.title}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

import React from 'react';
import { useNavigate } from 'react-router-dom';
import HomeNavbarSearchBigScreen from './HomeNavbarSearchBigScreen';
import Image from '../Image';
import { airplaneOutline, callOutline, cartOutline } from 'ionicons/icons'; // Importing icons from Ionicons
import { IonIcon } from '@ionic/react'; // Importing IonIcon component from @ionic/react
import { useAuth } from '../../components/AuthContext';
import CartCircularButton from '../widgets/CartCircularButton';
import '../../styles/navbar/navbar.css';
import '../../styles/widgets/loadinganimation.css';

function Navbar() {
  const { user } = useAuth();

  const navigate = useNavigate();

  return (
    <>
      <div className="nav-container">
        <div className='nav'>
          <div className="nav-logo" onClick={() => navigate('/')}>
            <img src="https://api.mylondoncorner.com/images/logo-black.png" alt="Logo" />
          </div>
          <div className="home-nav-search-big" style={{ marginLeft: '4%', width: '32%' }}>
            <HomeNavbarSearchBigScreen />
          </div>
          <ul className="nav-menu">
            <li><a href="#!" onClick={(e) => { e.preventDefault(); navigate('/trips'); }}><IonIcon icon={airplaneOutline} className="nav-icon-big" /></a></li>
            <li><a href="#!" onClick={(e) => { e.preventDefault(); navigate('/contact'); }}><IonIcon icon={callOutline} className="nav-icon-big" /></a></li>
            <li>  
              <a href="#!" onClick={(e) => { e.preventDefault(); navigate('/basket'); }}>
                <IonIcon icon={cartOutline} className="nav-icon-big" />
              </a>
            </li>
            <li>
            {user ? (
              <>
                {user.email === 'aleripod@gmail.com' || user.email === 'info@mylondoncorner.com' ? (
                  <a href="#!" onClick={(e) => { e.preventDefault(); navigate('/admin'); }}>
                    <Image
                      url={"https://api.mylondoncorner.com/images/queens-guard-black.png"}
                      style={{ width: '30px', height: '30px', marginRight: '5px', display: 'inline-block', borderRadius: '50%' }}
                      className='home-nav-icon-big'
                    />
                  </a>
                ) : (null)}
                <a href="#!" onClick={(e) => { e.preventDefault(); navigate('/account'); }}>
                  <Image
                    url={user.photoURL ? user.photoURL : "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"}
                    style={{ width: '30px', height: '30px', marginRight: '5px', display: 'inline-block', borderRadius: '50%' }}
                    className='home-nav-icon-big'
                  />
                </a>
              </>
            ) : (
              <a href="#!" onClick={(e) => { e.preventDefault(); navigate('/login'); }}>
                <Image
                  url={"https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"}
                  style={{ width: '30px', height: '30px', marginRight: '5px', display: 'inline-block', borderRadius: '50%' }}
                  className='home-nav-icon-big'
                />
              </a>
            )}
            </li>
          </ul>
          <br />
        </div>
      </div>
      <CartCircularButton />
    </>
  );
}

export default Navbar;

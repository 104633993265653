import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../../components/AuthContext';
import { useNavigate } from 'react-router-dom';

import Footer from "../../../components/Footer";
import Navbar from "../../../components/navbar/Navbar";

function AdminTourBookings() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [userData, setUserData] = useState({});
  const [loadingUserData, setLoadingUserData] = useState({});

  const [tourBookingsData, setTourBookingsData] = useState({});
  const [loadingtourBookingsData, setLoadingTourBookingsData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.uid) {
        try {
          setLoadingUserData(true);
          const response = await axios.get(`https://api.mylondoncorner.com/user/${user.uid}`, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY
            }
          });
          setUserData(response.data);
          setLoadingUserData(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoadingUserData(false);
        }
      } else {
        console.log("User is not authenticated or user data is not available.");
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    setLoadingTourBookingsData(true);
    axios.get(`https://api.mylondoncorner.com/tourbookings`, {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY
      }
    })
    .then((response) => {
      setTourBookingsData(response.data);
      setLoadingTourBookingsData(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingTourBookingsData(false);
    });
  }, []);

  const getBackgroundColor = (status) => {
    switch (status) {
      case 'pending':
        return '#FFA500';
      case 'cancelled':
        return '#FF6347';
      case 'completed':
        return '#4CAF50';
      default:
        return 'white';
    }
  };

  return (
    <div className="table-container">
      {loadingUserData ? (
        <div className="loading-animation"></div>
      ) : (
        Object.keys(userData).length !== 0 ? (
          userData.user.type === 4324143543 ? (
            <>
              <Navbar />

              <br />

              <button className='button' onClick={() => navigate('/admin/tour-booking/new')}>New</button>

              {loadingtourBookingsData ? (
                <div className="loading-animation"></div>
              ) : (
                <>
                  <br />
                  <table>
                    <tr>
                      <th>Image</th>
                      <th>Customer E-mail</th>
                      <th>Tour</th>
                      <th>Tour Guide</th>
                      <th>Tour Duration</th>
                      <th>Tour Date</th>
                      <th>Phone Number</th>
                      <th>Adult</th>
                      <th>Children</th>
                      <th>Total Paid</th>
                      <th>Source</th>
                      <th>Discount Code</th>
                      <th>Notes</th>
                      <th>Order Date</th>
                      <th>Edit</th>
                    </tr>
                    {tourBookingsData.tourBookings.map((tourBooking, index) => (
                        <tr key={index} style={{ backgroundColor: getBackgroundColor(tourBooking.status) }}>
                          <td><img src={'https://api.mylondoncorner.com/' + tourBooking.tour.images_paths[0]} style={{ width: '80px' }} /></td>
                          <td>{tourBooking.user ? tourBooking.user.email : tourBooking.guestEmail ? tourBooking.guestEmail : tourBooking.externalEmail}</td>
                          <td>{tourBooking.tour.name}</td>
                          <td><b>{tourBooking.tourGuide.name}</b></td>
                          <td>{tourBooking.duration ? tourBooking.duration + ' ore' : tourBooking.tour.duration}</td>
                          <td><b>{formatDate(tourBooking.date)}</b></td>
                          <td>{tourBooking.phoneNumber}</td>
                          <td>{tourBooking.quantity_adult}</td>
                          <td>{tourBooking.quantity_child}</td>
                          <td>{"£" + Math.round((tourBooking.quantity_adult * tourBooking.price_adult + tourBooking.quantity_child * tourBooking.price_child) * 100) / 100}</td>
                          <td>{tourBooking.user ? "Registered User" : tourBooking.guestEmail ? "Guest" : "External"}</td>
                          <td>{tourBooking.discountCode}</td>
                          <td>{tourBooking.notes}</td>
                          <td>{formatDate(tourBooking.createdAt)}</td>
                          <td><button className='button' onClick={() => navigate('/admin/tour-booking/edit/' + tourBooking._id)}>Edit</button></td>
                        </tr>
                    ))}
                  </table>                
                </>
              )}

              <Footer />
            </>
          ) : (
            navigate('/')
          )
        ) : (
          navigate('/')
        )
      )}
    </div>
  );
}

// Define a function to format the date
function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
}

export default AdminTourBookings;